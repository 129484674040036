import React from "react";
import { StaticQuery, graphql } from "gatsby";

const Footer: React.FC = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            urls {
              github
              linkedin
            }
          }
        }
      }
    `}
    render={(data) => {
      const { github, linkedin } = data.site.siteMetadata.urls;
      return (
        <div id="footer">
          <div className="inner">
            <ul className="icons">
              <li>
                <a href={github} className="icon fa-github">
                  <span className="label">Github</span>
                </a>
              </li>
              <li>
                <a href={linkedin} className="icon fa-linkedin">
                  <span className="label">LinkedIn</span>
                </a>
              </li>
              {/* <li>
                <a href="#" className="icon fa-envelope-o">
                  <span className="label">Email</span>
                </a>
              </li> */}
            </ul>
            <ul className="copyright">
              <li>
                Based on Strata from <a href="http://html5up.net">HTML5 UP</a>
              </li>
              <li>Photos &copy; Ian O&apos;Connor</li>
            </ul>
          </div>
        </div>
      );
    }}
  />
);

export default Footer;
