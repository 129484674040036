import React from "react";
import avatar from "../assets/images/avatar.png";
import Footer from "./Footer";
import { Link } from "gatsby";

const Header: React.FC = () => (
  <header id="header">
    <div className="inner">
      <Link to="/" className="image avatar">
        <img src={avatar} alt="" />
      </Link>
      <h1>
        <strong>Ian O&apos;Connor</strong> <br />
        Software Developer <br />
        Milwaukee, WI
      </h1>
      {/* <SideBarTree
        links={[
          { to: "/", name: "Home" },
          { to: "/projects/table", name: "Table" },
          { to: "/projects/nuclear-engine", name: "NuclearEngine" },
        ]}
      /> */}
    </div>
    <Footer />
  </header>
);

export default Header;
